import AboutMe from '../AboutMe/AboutMe';
import Offer from '../Offer/Offer';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';

import { ContentWrapper } from './MainContent.styles';
import { NavItems } from '../../types/navItems.type';

import { GrCube } from 'react-icons/gr';

type MainContentProps = {
    listNames: NavItems[];
};

const MainContent = ({ listNames }: MainContentProps) => {
    const titleLists = listNames.map(el => el.id);

    return (
        <>
            <ContentWrapper>
                <AboutMe id={titleLists[0]} />
                <Offer id={titleLists[1]} />
                <section>
                    <h2>
                        <GrCube />
                        KONTAKT
                    </h2>
                </section>
            </ContentWrapper>
            <Contact id={titleLists[2]} />
            <Footer />
        </>
    );
};

export default MainContent;
