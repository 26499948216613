import { InfoWrapper } from './OfferItem.styles';

import { OfferItemType } from '../../types/navItems.type';
import { AiFillStar } from 'react-icons/ai';

type OfferItemProps = {
    item: OfferItemType;
};

const OfferItem = ({ item: { title, description } }: OfferItemProps) => {
    return (
        <InfoWrapper>
            <h3>
                <AiFillStar /> {title}
            </h3>
            <p>{description}</p>
        </InfoWrapper>
    );
};

export default OfferItem;
