import styled from 'styled-components';
import { device } from '../../styles/device';

export const StickyHeader = styled.div`
    z-index: 999990;
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    background-color: #ffffff;

    -webkit-box-shadow: 0px 4px 14px -8px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 14px -8px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 14px -8px rgba(0, 0, 0, 0.2);

    @media ${device.mobile} {
        background-color: var(--background-header-mobile-color);
    }
`;

export const ListWrapper = styled.div`
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
`;

export const NavElements = styled.ul<{ isOpen: boolean }>`
    list-style: none;
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    transition: 0.4s;

    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: var(--header-height);
        margin: -2px 0 0;
        padding: 0;
        transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-100%)')};
        transition: 0.4s;
        z-index: -1;
    }

    li {
        padding: 0 20px;
        min-height: 50px;
        display: flex;

        @media ${device.mobile} {
            justify-content: flex-start;
            align-items: center;
            border-top: ${({ isOpen }) => (isOpen ? '1px solid lightgray;' : '0px solid lightgray;')};

            &:last-child {
                border-bottom: ${({ isOpen }) => (isOpen ? '1px solid lightgray;' : '0px solid lightgray;')};
            }

            background-color: var(--background-header-mobile-color);
        }

        span {
            height: 100%;
            display: flex;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 17px;
            font-weight: 700;
            color: var(--primary-font-color);

            @media ${device.desktop} {
                &:hover {
                    color: var(--hover-button-text-color);
                    transition: 0.4s;
                }

                &.active {
                    border-bottom: 4px solid var(--hover-button-text-color);
                    transition: 0.3s ease;
                }
            }

            @media ${device.mobile} {
                z-index: ${({ isOpen }) => (isOpen ? 0 : -1)};
                transition: ${({ isOpen }) => (isOpen ? '1s' : '0.1s')};
            }
        }
    }
`;

export const HeaderWrapper = styled.nav`
    display: flex;
    align-items: center;
    padding: 0 20px;

    @media ${device.mobile} {
        padding: 0;
        display: flex;
        flex-direction: row-reverse;
    }

    div.open {
        span:nth-child(1) {
            transform: rotate(45deg);
            position: absolute;
        }

        span:nth-child(2) {
            display: none;
        }

        span:nth-child(3) {
            transform: rotate(-45deg);
        }
    }

    @media ${device.mobile} {
        div {
            @media ${device.tablet} {
                display: none;
            }

            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);

            @media ${device.mobile} {
                left: 30px;
                width: 30px;
            }

            span {
                display: block;
                width: 40px;
                height: 4px;
                background-color: var(--primary-font-color);
                margin-bottom: 8px;
                border-radius: 5px;
                transition: 0.6s;

                @media ${device.mobile} {
                    width: 30px;
                    margin-bottom: 4px;
                }
            }
        }
    }
`;

export const Logo = styled.img`
    max-width: 150px;
    max-height: 90px;
    cursor: pointer;

    @media ${device.mobile} {
        max-height: 65px;
        max-width: 120px;
    }
`;
