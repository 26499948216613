import { MainImageWrapper, MainImage, TitleSection } from './MainImageContent.styles';
import mainImage from '../../images/main_image.jpg';

const MainImageContent = () => {
    return (
        <MainImageWrapper>
            <MainImage src={mainImage} alt="mainImage" />
            <TitleSection>
                <h1>ManMath</h1>
                <h4>RAFAŁ MAŃKOWSKI</h4>
            </TitleSection>
            <div id="about-me"></div>
        </MainImageWrapper>
    );
};

export default MainImageContent;
