import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
html, body {
    :root {
        --primary-font-color: #424242;
        --hover-button-text-color: #0097ff;
        --secendory-font-color: #bbd5ff;
        --primary-divider-color: #bbd5ff;
        --purple: #93349b;
        --dark-blue: #0d2973;
        --header-height: 65px;
        --header-top-banner-height: 30px;
        --border: 1px solid var(--primary-divider-color);
        --background-offer-color: #F2F5F7;
        --background-header-mobile-color: #ffffff;
        --red-color-1: #FF0057;
    }

    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}
`;

export default GlobalStyle;
