const size = {
    mobile: '810px',
    tablet: '811px',
    desktop: '1024px'
};

export const device = {
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tablet}) and 
    (max-width: 1023px)`,
    desktop: `(min-width: ${size.desktop})`
};
