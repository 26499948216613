import { useState } from 'react';

import { Button } from './ScrollButton.styles';

import { FaArrowCircleUp } from 'react-icons/fa';

type ScrollButtonProps = {
    onClick: () => void;
};

const ScrollButton = ({ onClick }: ScrollButtonProps) => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;

        const scrollBtnNumberWhenVisible = window.document.body.offsetHeight * 0.4;

        if (scrolled > scrollBtnNumberWhenVisible) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    window.addEventListener('scroll', toggleVisible);
    return (
        <Button>
            <FaArrowCircleUp onClick={onClick} style={{ display: visible ? 'inline' : 'none' }} />
        </Button>
    );
};

export default ScrollButton;
