import styled from 'styled-components';
import { device } from '../../styles/device';

export const TopBanner = styled.div`
    color: var(--dark-blue);
    background-color: black;
    min-height: var(--header-top-banner-height);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--primary-divider-color);
    padding-block: 10px;
    margin-top: -5px;
    font-weight: 600;

    h2 {
        margin-block: 5px 15px;
        animation-name: example;
        animation-duration: 5s;
        animation-iteration-count: infinite;
    }

    h3 {
        margin-block: 10px;
        cursor: pointer;
    }

    @media ${device.mobile} {
        padding: 10px;
        text-align: center;
    }

    @keyframes example {
        0% {
            transform: scale(1);
            color: var(--red-color-1);
        }
        50% {
            transform: scale(1.2);
            color: var(--hover-button-text-color);
        }
        100% {
            transform: scale(1);
            color: var(--red-color-1);
        }
    }
`;
