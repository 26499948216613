import styled from 'styled-components';
import { device } from '../../styles/device';

export const InfoWrapper = styled.div`
    background-color: var(--background-offer-color);
    border-radius: 5px;
    padding: 0 10px 10px;
    box-shadow: 2px 3px 15px -8px rgba(66, 68, 90, 1);

    @media ${device.desktop} {
        &:hover {
            transform: scale(1.05);
            transition: 0.5s;
            cursor: pointer;
        }
    }

    @media ${device.mobile} {
        width: 90%;
    }

    h3 {
        color: var(--primary-font-color);
        display: flex;
        align-items: center;
        border-bottom: var(--border);
        padding-bottom: 10px;

        @media ${device.tablet} {
            min-height: 50px;
        }

        svg {
            margin-right: 20px;
            path {
                fill: var(--secendory-font-color);
            }
        }
    }
`;
