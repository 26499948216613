import styled from 'styled-components';
import { device } from '../../styles/device';

export const MainImageWrapper = styled.div`
    margin-top: var(--header-height);
    width: 100%;
    height: 100%;
    position: relative;
`;

export const TitleSection = styled.section`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--hover-button-text-color);
    text-align: center;
    font-size: 30px;

    h1 {
        font-weight: 800;
        margin-bottom: 0;

        @media ${device.mobile} {
            font-size: 34px;
        }
    }

    h4 {
        margin-top: 10px;

        @media ${device.mobile} {
            font-size: 20px;
        }
    }
`;

export const MainImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 600px;
    background-attachment: fixed;
    opacity: 0.9;
`;
