import { ContactBoxContent, LocalizationIconWrapper, PhoneIconWrapper, PhoneNumber } from './ContactBox.styles';

import { RiMapPin2Fill } from 'react-icons/ri';
import { BsFillTelephoneFill } from 'react-icons/bs';

const ContactBox = () => {
    return (
        <>
            <ContactBoxContent>
                <h2>LOKALIZACJA</h2>
                <LocalizationIconWrapper>
                    <RiMapPin2Fill />
                </LocalizationIconWrapper>
                <p>ul. Akacjowa 3</p>
                <p>64-030, Nowa Wieś</p>
                <p>
                    <a
                        href="https://www.google.com/maps/place/Akacjowa+3,+64-030+Nowa+Wie%C5%9B/@52.0297204,16.4953112,17z/data=!3m1!4b1!4m5!3m4!1s0x4705b9b9edff20c7:0xf3ea3929b7a75ef8!8m2!3d52.0297204!4d16.4974999?hl=pl-PL"
                        target="_blank"
                        rel="noreferrer">
                        Znajdź mnie na mapie
                    </a>
                </p>
            </ContactBoxContent>
            <ContactBoxContent>
                <h2>KONTAKT</h2>
                <PhoneIconWrapper>
                    <BsFillTelephoneFill />
                </PhoneIconWrapper>
                <p>
                    Numer telefonu:{' '}
                    <a href="tel:727928250">
                        <PhoneNumber>+ 48 727 928 250</PhoneNumber>
                    </a>
                </p>
                <p>
                    Adres email: <a href="mailto:manmath9009@gmail.com">manmath9009@gmail.com</a>
                </p>
                <p>
                    Facebook:{' '}
                    <a href="https://www.facebook.com/ManMath-103771392608446" target="_blank" rel="noreferrer">
                        ManMath
                    </a>
                </p>
            </ContactBoxContent>
        </>
    );
};

export default ContactBox;
