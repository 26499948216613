import { FooterWrapper } from './Footer.styles';

const Footer = () => {
    return (
        <FooterWrapper>
            <div>Copyright &copy; Rafał Mańkowski | 2023</div>
        </FooterWrapper>
    );
};

export default Footer;
