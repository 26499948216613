import styled from 'styled-components';

import { device } from '../../styles/device';

export const OfferSection = styled.section`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding-bottom: 16px;

    @media ${device.tablet} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.mobile} {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    h2 {
        grid-column: 1/4;

        @media ${device.tablet} {
            grid-column: 1/3;
        }

        @media ${device.mobile} {
            width: 100%;
        }
    }

    p {
        padding: 10px;
        line-height: 25px;
    }
`;
