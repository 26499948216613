import footerImage from '../../images/footer_image.jpg';
import { ContactWrapper, ContactBoxWrapper } from './Contact.styles';

import { MainImage } from '../MainImageContent/MainImageContent.styles';
import ContactBox from '../ContactBox/ContactBox';

type ContactProps = {
    id: string;
};

const Contact = ({ id }: ContactProps) => {
    return (
        <ContactWrapper id={id}>
            <MainImage src={footerImage} alt="mainImage" loading="lazy" />
            <ContactBoxWrapper>
                <ContactBox />
            </ContactBoxWrapper>
        </ContactWrapper>
    );
};

export default Contact;
