import OfferItem from '../OfferItem/OfferItem';
import { OfferItemType } from '../../types/navItems.type';

import { offerData } from './data';

import { OfferSection } from './Offer.styles';
import { GrCube } from 'react-icons/gr';

type OfferProps = {
    id: string;
};

const Offer = ({ id }: OfferProps) => {
    return (
        <OfferSection id={id}>
            <h2>
                <GrCube />
                OFERTA
            </h2>
            {offerData.map((item: OfferItemType) => (
                <OfferItem item={item} key={item.id} />
            ))}
        </OfferSection>
    );
};

export default Offer;
