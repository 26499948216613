import styled from 'styled-components';
import { device } from '../../styles/device';

export const ContentWrapper = styled.div`
    padding: 0 20px;
    width: 80%;
    margin: 20px auto;

    @media ${device.mobile} {
        width: auto;
    }

    section:first-child {
        h2 {
            border-top: none;
        }
    }

    h2 {
        color: var(--primary-font-color);
        display: flex;
        align-items: center;
        padding-top: 30px;
        border-top: var(--border);

        svg {
            margin-right: 20px;

            path {
                fill: var(--secendory-font-color);
            }
        }
    }
`;
