import styled from 'styled-components';
import { device } from '../../styles/device';

export const ContactWrapper = styled.section`
    width: 100%;
    height: 100%;
    position: relative;

    img {
        @media ${device.mobile} {
            height: 600px;
        }
    }
`;

export const ContactBoxWrapper = styled.div`
    width: 700px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 50px;

    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
`;
