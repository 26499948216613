import { TopBanner } from './CourseInformationTopBanner.styles';
import { scroller } from 'react-scroll';

const CourseInformationTopBanner = () => {
    const handleScrollToContact = () => {
        scroller.scrollTo('contact', {
            duration: 600,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: 20
        });
    };

    return (
        <TopBanner>
            <h2>Uwaga!</h2>
            <span>
                Ruszyły zapisy na <b>kurs maturalny online 2023/2024</b> na poziomie podstawowym i rozszerzonym. 💪🔥
            </span>
            <h3 onClick={handleScrollToContact}>Zapraszam do kontaktu 🙂</h3>
        </TopBanner>
    );
};

export default CourseInformationTopBanner;
