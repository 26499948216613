import { AboutMeSection } from './AboutMe.styles';

import { GrCube } from 'react-icons/gr';

type AboutMeProps = {
    id: string;
};

const AboutMe = ({ id }: AboutMeProps) => {
    return (
        <AboutMeSection id={id}>
            <h2>
                <GrCube />O MNIE
            </h2>
            <p>
                Od niedawna jestem nietypowym <strong> nauczycielem matematyki, egzaminatorem</strong>, który już od
                ponad dekady skutecznie przygotowuje uczniów do różnego rodzaju egzaminów. Głównie specjalizuję się w
                przygotowaniu uczniów do <strong> egzaminu maturalnego z matematyki </strong> zarówno na poziomie{' '}
                <strong>podstawowoym</strong> jak i <strong>rozszerzonym</strong>.
            </p>
            <p>
                Wieloletnie doświadczenie pozwoliło mi wypracować umiejętności indywidualnego podejścia do ucznia i
                dostosowanie metod do sposobu jego myślenia. Ponadto dzięki temu oraz płynności w poruszaniu się po
                różnych dziedzinach matematyki docieram w przejrzysty i prosty sposób do większości uczniów. Świadomość
                istotnych zagadnień pomaga osiągać z uczniami odpowiednie i satysfakcjonujęce wyniki.{' '}
                <strong>Jak dotąd wszyscy podopieczni zdali maturę - 100% skuteczności.</strong>
            </p>
            <p>
                Organizuję zajęcia z matematyki zarówno w trybie stacjonarnym jak i zdalnym. Każdego dnia staram się nie
                tylko pomagać zrozumieć matematykę i zrealizować cele uczniów, ale także pokazać, iż matematyka może
                przynosić radość i przyjemność. Dzięki swojemu wieloletniemu doświadczeniu zwracam uwagę na aspekty
                <strong> stricte maturalne ucząc niezbędnych rzeczy </strong>, co przekłada się na rezultaty edekwatne
                do umiejętności i oczekiwań.
            </p>
            <p>
                <strong>UWAGA! Liczba miejsc na zajęciach stacjonarnych ograniczona.</strong>
            </p>
        </AboutMeSection>
    );
};

export default AboutMe;
