import CourseInformationTopBanner from './components/CourseInformation/CourseInformationTopBanner';
import Header from './components/Header/Header';
import MainContent from './components/MainContent/MainContent';
import MainImageContent from './components/MainImageContent/MainImageContent';
import { NavItems } from './types/navItems.type';

const listNames: NavItems[] = [
    { name: 'o mnie', id: 'about-me' },
    { name: 'oferta', id: 'offer' },
    { name: 'kontakt', id: 'contact' }
];

function App() {
    return (
        <div className="App">
            <Header listNames={listNames} />
            <MainImageContent />
            <CourseInformationTopBanner />
            <MainContent listNames={listNames} />
        </div>
    );
}

export default App;
