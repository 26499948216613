import styled from 'styled-components';

import { device } from '../../styles/device';

export const ContactBoxContent = styled.div`
    background-color: var(--background-offer-color);
    border-radius: 5px;
    padding: 0 10px 10px;
    box-shadow: 2px 3px 15px -8px rgba(66, 68, 90, 1);
    width: 900px;
    height: 300px;

    @media ${device.mobile} {
        width: 200px;
        height: 250px;
    }

    p {
        text-align: center;
        font-size: 14px;

        a {
            color: red;
            &:hover {
                color: var(--hover-button-text-color);
            }
        }
    }

    h2 {
        text-align: center;
    }

    svg {
        width: 40px;
        height: 40px;
    }
`;

export const LocalizationIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        g {
            path:last-child {
                fill: var(--hover-button-text-color);
            }
        }
    }
`;

export const PhoneIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        path {
            fill: var(--hover-button-text-color);
        }
    }
`;

export const PhoneNumber = styled.span`
    @media ${device.mobile} {
        display: block;
    }
`;
