import { useState } from 'react';
import { StickyHeader, ListWrapper, HeaderWrapper, Logo, NavElements } from './Header.styles';
import { scroller } from 'react-scroll';
import { NavItems } from '../../types/navItems.type';
import { scrollToTop } from '../../Util/ScrollToTop/ScrollToTop';
import { isMobile } from '../../Util/Mobile/isMobile';

import logo from '../../images/logo.jpg';
import transparentLogo from '../../images/transparent_logo.png';
import ScrollButton from '../ScrollButton/ScrollButton';

type HeaderProps = {
    listNames: NavItems[];
};

const Header = ({ listNames }: HeaderProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeId, setActiveId] = useState<string>('');

    const mobile: boolean = isMobile.any();

    const getOffsetValue = (id: string) => {
        switch (id) {
            case 'contact':
                return mobile ? -140 : -165;
            case 'offer':
                return mobile ? -40 : -65;
            default:
                return mobile ? -65 : -90;
        }
    };

    const handleItemListClick = () => {
        if (mobile) {
            setIsOpen(currentIsOpen => !currentIsOpen);
        }
    };

    const scrollToSection = (id: string) => {
        if (!mobile) {
            setActiveId(id);
        }

        const offsetValue = getOffsetValue(id);

        scroller.scrollTo(id, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: offsetValue
        });

        if (mobile) {
            setIsOpen(false);
        }
    };

    const handleScrollToTop = () => {
        scrollToTop();
        setActiveId('');
    };

    return (
        <>
            <StickyHeader>
                <ListWrapper>
                    <HeaderWrapper>
                        <Logo
                            src={!mobile ? logo : transparentLogo}
                            alt="logo"
                            loading="lazy"
                            onClick={handleScrollToTop}
                        />
                        <div onClick={handleItemListClick} className={isOpen ? 'open' : ''}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <NavElements isOpen={isOpen}>
                            {listNames.map(({ name, id }) => {
                                return (
                                    <li key={id} onClick={() => scrollToSection(id)}>
                                        <span className={activeId === id ? 'active' : ''}>{name}</span>
                                    </li>
                                );
                            })}
                        </NavElements>
                    </HeaderWrapper>
                </ListWrapper>
            </StickyHeader>
            <ScrollButton onClick={handleScrollToTop} />
        </>
    );
};

export default Header;
