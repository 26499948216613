export const offerData = [
    {
        id: 1,
        title: 'Rodzaje zajęć',
        description:
            'Zajęcia skierowane są dla uczniów na poziomie 7-8 klasy szkoły podstawowowej, wszystkich klas szkół średnich na poziomie podstawowym i rozszerzonym. Dodatkowo prowadzone są również zajęcia dla studentów.'
    },
    {
        id: 2,
        title: 'Kursy maturalne',
        description:
            'Pełny maturalny kurs rozpoczyna się we wrześniu, jednakże można do niego przystąpić w późniejszym terminie. Zadania są skrupulatnie selekcjonowane i dostosowane do wyboru poziomu zaliczenia egzaminu maturalnego przez ucznia.'
    },
    {
        id: 3,
        title: 'Liczba osób na zajęciach',
        description:
            'Stacjonarnie preferuje zajęcia do max. 2 osób. W trybie zdalnym są dostępne zajęcia w grupach liczniejszych z podziałem na tematykę, poziom kształcenia.'
    },
    {
        id: 4,
        title: 'Dostosowanie zajęć',
        description:
            'Zajęcia dostosowane są do potrzeb ucznia. Mogą przyjąć formę realizacji bieżącego materiału, powtórki do sprawdzianu, przygotowanie do egzaminu ósmoklasisty, matury, przygotowanie do kolokwium na studiach.'
    },
    {
        id: 5,
        title: 'Poziom kursu maturalnego',
        description:
            'Na początku uczeń deklaruje swoje oczekiwania odnośnie wyniku z egzaminu. Po upływie mniej więcej miesiąca, wspólnie weryfikujemy te oczekiwania, podnosząc lub obniżając próg zgodnie z zaprezentowanymi umiejętnościami w celu dostosowania poziomu zadań maturalnych, aby nasz wspólny cel został zrealizowany.'
    },
    {
        id: 6,
        title: 'Dbałość o szczegóły',
        description:
            'Specjalistyczne podejście plus nieszablonowe rozwiązania, upraszczanie rozwiązań i pokazywanie różnych wariantów.'
    },
    {
        id: 7,
        title: 'Tryb zajęć',
        description: 'Zajęcia zarówno stacjonarne w podanej lokalizacji jak i w trybie zdalnym.'
    },
    {
        id: 8,
        title: 'Tryb zdalny',
        description:
            'Dla grup wielosoobowych tworzone są grupy na portalu, gdzie prócz zajęć z uczniami na platformie isnieje możliwość ciągłego kontaktu i wspólnego rozwiązywania trudności. Dodatkowo służę pomocą po zajęciach przy rozwiązywaniu zadań gruntujących poznawane zagadnienia i umiejętności.'
    },
    {
        id: 9,
        title: 'Czas zajęć',
        description:
            'Zajęcia trwają jedną lub dwie godziny lekcyjne. Możliwa jest również pełna godzina. Dla matury rozszeroznej w klasie maturalnej wskazane są dwie godziny lekcyjne.'
    }
];
