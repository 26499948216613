import styled from 'styled-components';

export const FooterWrapper = styled.footer`
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background-color: var(--hover-button-text-color);
    margin-top: -5px;
    padding: 5px 0;
    color: white;
`;
