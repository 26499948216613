import styled from 'styled-components';

export const Button = styled.div`
    position: fixed;
    right: 20px;
    bottom: 50px;
    height: 40px;
    font-size: 3rem;
    z-index: 100;
    cursor: pointer;
    color: var(--hover-button-text-color);
`;
